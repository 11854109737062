<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br />
        <br />
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Publisher</strong>
            </div>
            <div class="text-muted">Edit publisher information</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's name.</span>
                  </template>
                  <a-input placeholder="Publisher Name" v-decorator="['name', {
                    initialValue: publisherData.Name, rules: [
                      { required: true, message: 'Name is required.' },
                      { max: 64, message: 'Maximum 64 characters allowed.' }
                    ]
                  }]" />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Email">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's e-mail address.</span>
                  </template>
                  <a-input type="email" placeholder="Publisher Email Address" v-decorator="['email', {
                    initialValue: publisherData.Mail,
                    rules: [
                      { required: true, message: 'Email is not valid.', pattern: emailPattern },
                      { max: 64, message: 'Maximum 64 characters allowed.' }
                    ]
                  }]" />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Username">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's username. It is required for fetching statistics through stats API or CSV
                      export.</span>
                  </template>
                  <a-input disabled type="text" placeholder="Publisher Username"
                    v-decorator="['username', { initialValue: publisherData.Username }]" />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Country">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's country.</span>
                  </template>
                  <a-select v-decorator="['country', {
                    initialValue: (publisherData.Country === '' ? [] : publisherData.Country),
                    rules: [{ required: true, message: 'Country is required.' }]
                  }]" placeholder="Please select a country"
                    :showSearch="true" :filterOption="countryFilter" optionFilterProp="children">
                    <a-select-option v-for="obj in info.countries" :key="obj.CC"><img
                        :src="matchIcon(countries, obj.CC)" /> {{ obj.Country }}</a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's description.</span>
                  </template>
                  <a-input placeholder="Publisher Description" v-decorator="['description', {
                    initialValue: publisherData.Description,
                    rules: [
                      { max: 512, message: 'Maximum 512 characters allowed.' }
                    ]
                  }]" />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Website">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's website.</span>
                  </template>
                  <a-input placeholder="Publisher Website" v-decorator="['website', {
                    initialValue: publisherData.Website,
                    rules: [
                      { max: 64, message: 'Maximum 64 characters allowed.' }
                    ]
                  }]" />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="City">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's city.</span>
                  </template>
                  <a-input placeholder="City" v-decorator="['city', {
                    initialValue: publisherData.City,
                    rules: [
                      { max: 20, message: 'Maximum 20 characters allowed.' }
                    ]
                  }]" />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Zip Code">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's zip code.</span>
                  </template>
                  <a-input type="number" placeholder="Zip Code" v-decorator="['zipCode', {
                    initialValue: publisherData.ZipCode,
                    rules: [{ transform: function (value) { return Number(value) }, type: 'number' }]
                  }]" />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Address">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's address.</span>
                  </template>
                  <a-input placeholder="Address" v-decorator="['address', {
                    initialValue: publisherData.Address, rules: [
                      { max: 64, message: 'Maximum 64 characters allowed.' }
                    ]
                  }]" />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Phone">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's phone number.</span>
                  </template>
                  <a-input placeholder="Phone" v-decorator="['phone', {
                    initialValue: publisherData.Phone,
                    rules: [
                      { message: 'Invalid phone number.' },
                      { max: 20, message: 'Maximum 20 characters allowed.' }
                    ]
                  }]" />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Skype">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's Skype username.</span>
                  </template>
                  <a-input placeholder="Skype" v-decorator="['skype', {
                    initialValue: publisherData.Skype,
                    rules: [
                      { max: 32, message: 'Maximum 32 characters allowed.' }
                    ]
                  }]" />
                </a-tooltip>
              </a-form-item>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                    :class="'btn btn-' + (publisherData.Status === 0 ? 'danger' : 'primary') + ' px-5 ml-2'"
                    @click.prevent="togglePublisherStatus">
                    {{ publisherData.Status == 0 ? 'Deactivate' : 'Activate' }} Publisher
                  </button>
                  <button type="button" class="btn px-5 ml-2" @click="$router.push({ name: 'xml-publishers' })">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
import staticCountryData from '@/resources/countries.json'

export default {
  components: {},
  computed: {
    ...mapState(['info']),
    publisherData: function () {
      let publisherData = this.$store.getters['XMLpublisher/getPublisher'](this.$route.params.id)
      if (typeof publisherData === 'undefined') {
        this.$store.dispatch('XMLpublisher/LOAD_PUBLISHERS')
        publisherData = {}
      }
      return publisherData
    },
  },
  data() {
    return {
      countries: staticCountryData,
      emailPattern: '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])',
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    countryFilter(inputValue, option) {
      return option.componentOptions.children[1].text.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) { return false } else {
        const image = cc.image
        return image
      }
    },
    togglePublisherStatus() {
      const status = this.publisherData.Status === 0 ? 'Deactivate' : 'Activate'
      const instance = this
      Modal.confirm({
        title: 'Are you sure you want to change publisher status?',
        content: h => <div>{status} publisher <strong>{this.publisherData.Name}</strong>?</div>,
        onOk() {
          const newStatus = instance.publisherData.Status === 0 ? 1 : 0
          instance.$store.dispatch('XMLpublisher/CHANGE_STATUS', {
            id: instance.publisherData.Id,
            status: newStatus,
          })
        },
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.website !== '' && values.website) {
            values.website = values.website.replace(/\s/g, '')
          }
          this.$store.dispatch('XMLpublisher/UPDATE_PUBLISHER', {
            id: this.publisherData.Id,
            payload: values,
            callback: function () {
              this.$router.push({ name: 'xml-publishers' })
            }.bind(this),
          })
        }
      })
    },
    placeholder(key) {
      return (typeof this.publisherData[key] === 'undefined') ? '' : this.publisherData[key]
    },
  },
  created() {
    this.$store.dispatch('info/LOAD_COUNTRIES')
    this.$store.dispatch('XMLpublisher/LOAD_PUBLISHERS')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
